export const AccessEnum = {
    DASHBOARD : 0,
}

export const QueueAccessEnum = {
    QUEUE_TODAY_VIEW : 1,
    QUEUE_TODAY_ADD : 2,
    QUEUE_TODAY_EDIT : 3,
    QUEUE_TODAY_DELETE : 4,
    QUEUE_TODAY_ENGAGE : 5,
    QUEUE_TODAY_CHECKOUT : 6,

    QUEUE_PAST_VIEW : 7,
    QUEUE_PAST_ADD : 8,
    QUEUE_PAST_EDIT : 9,
    QUEUE_PAST_DELETE : 10,
}

export const PatientAccessEnum = {
    PATIENT_LIST_VIEW : 11,
    PATIENT_LIST_ADD : 12,
    PATIENT_LIST_EDIT : 13,

    PATIENT_NEW_VIEW : 14,
    PATIENT_NEW_ADD : 15,
    PATIENT_NEW_EDIT : 16,
}

export const AppointmentAccessEnum = {
    APPOINTMENT_LIST_VIEW : 17,
    APPOINTMENT_LIST_ADD : 18,
    APPOINTMENT_LIST_EDIT : 19,
    APPOINTMENT_LIST_DELETE : 20,

    APPOINTMENT_CALENDAR_VIEW : 21,
    APPOINTMENT_CALENDAR_ADD : 22,
    APPOINTMENT_CALENDAR_EDIT : 23,
    APPOINTMENT_CALENDAR_DELETE : 24,
}

export const PrescriptionAccessEnum = {
    PRESCRIPTION_VIEW : 25,
    PRESCRIPTION_ADD : 26,
    PRESCRIPTION_EDIT : 27,
    PRESCRIPTION_DELETE : 28,
}

export const InvoicesAccessEnum = {
    INVOICES_VIEW : 29,
    INVOICES_ADD : 30,
    INVOICES_EDIT : 31,
    INVOICES_DELETE : 32,

    INVOICES_DETAILED_VIEW : 33,
    INVOICES_DETAILED_ADD : 34,
    INVOICES_DETAILED_EDIT : 35,
    INVOICES_DETAILED_DELETE : 36,

    GROUP_INVOICES_VIEW : 37,
    GROUP_INVOICES_ADD : 38,
    GROUP_INVOICES_EDIT : 39,
    GROUP_INVOICES_DELETE : 40,

    POSTED_GROUP_INVOICES_VIEW : 41,
    POSTED_GROUP_INVOICES_ADD : 42,
    POSTED_GROUP_INVOICES_EDIT : 43,
    POSTED_GROUP_INVOICES_DELETE : 44,

    QUOTATION_VIEW : 45,
    QUOTATION_ADD : 46,
    QUOTATION_EDIT : 47,
    QUOTATION_DELETE : 48,

    CREDIT_NOTE_VIEW : 49,
    CREDIT_NOTE_ADD : 50,
    CREDIT_NOTE_EDIT : 51,
    CREDIT_NOTE_DELETE : 52,
}

export const InventoryAccessEnum = {
    INVENTORY_VIEW : 53,
    INVENTORY_ADD : 54,
    INVENTORY_EDIT : 55,
    INVENTORY_DELETE : 56,
    INVENTORY_VIEW_INACTIVE : 57,

    VENDOR_GOODS_RECEIVED_VIEW : 58,
    VENDOR_GOODS_RECEIVED_ADD : 59,
    VENDOR_GOODS_RECEIVED_EDIT : 60,
    VENDOR_GOODS_RECEIVED_DELETE : 61,

    VENDOR_PURCHASE_ORDER_VIEW : 62,
    VENDOR_PURCHASE_ORDER_ADD : 63,
    VENDOR_PURCHASE_ORDER_EDIT : 64,
    VENDOR_PURCHASE_ORDER_DELETE : 65,

    VENDOR_PURCHASE_RETURN_VIEW : 66,
    VENDOR_PURCHASE_RETURN_ADD : 67,
    VENDOR_PURCHASE_RETURN_EDIT : 68,
    VENDOR_PURCHASE_RETURN_DELETE : 69,

    BRANCH_GOODS_RECEIVED_VIEW : 70,
    BRANCH_GOODS_RECEIVED_ADD : 71,
    BRANCH_GOODS_RECEIVED_EDIT : 72,
    BRANCH_GOODS_RECEIVED_DELETE : 73,
    BRANCH_GOODS_RECEIVED_ACKNOWLEDGE : 74,

    BRANCH_GOODS_RETURN_VIEW : 75, 
    BRANCH_GOODS_RETURN_ADD : 76,
    BRANCH_GOODS_RETURN_EDIT : 77,
    BRANCH_GOODS_RETURN_DELETE : 78,

    BRANCH_STOCK_TRANSFER_VIEW : 79,
    BRANCH_STOCK_TRANSFER_ADD : 80,
    BRANCH_STOCK_TRANSFER_EDIT : 81,
    BRANCH_STOCK_TRANSFER_DELETE : 82,
    BRANCH_STOCK_TRANSFER_ACKNOWLEDGE : 83,
}

export const ReportsAccessEnum = {
    REPORT_MEDICAL_CERIFICATE_VIEW : 84,
    REPORT_INVOICE_VIEW : 85,
    REPORT_PATIENTS_VIEW : 86,
    REPORT_PAYMENTS_VIEW : 87,
    REPORT_ENDOFDAY_VIEW : 88,
    REPORT_AGINGS_VIEW : 89,
    REPORT_CLINIC_SERIVCES_VIEW : 90,
    REPORT_DISPENSE_VIEW : 91,
    REPORT_STOCK_ADJUSTMENT_VIEW : 92,
    REPORT_APPOINTMENTS_VIEW : 93,
    REPORT_QUEUE_VIEW : 94,
}

export const MaintenanceAccessEnum = {
    MAINTENANCE_ROLE_ACCESS_VIEW : 95, 
    MAINTENANCE_ROLE_ACCESS_ADD : 96, 
    MAINTENANCE_ROLE_ACCESS_EDIT : 97,

    MAINTENANCE_CLINIC_VIEW : 98, 
    MAINTENANCE_CLINIC_ADD : 99, 
    MAINTENANCE_CLINIC_EDIT : 100,
    MAINTENANCE_CLINIC_DELETE : 101,

    MAINTENANCE_INSURANCE_VIEW : 102,
    MAINTENANCE_INSURANCE_ADD : 103,
    MAINTENANCE_INSURANCE_EDIT : 104,
    MAINTENANCE_INSURANCE_DELETE : 105,

    MAINTENANCE_SUPPLIER_VIEW : 106,
    MAINTENANCE_SUPPLIER_ADD : 107,
    MAINTENANCE_SUPPLIER_EDIT : 108,
    MAINTENANCE_SUPPLIER_DELETE : 109,

    MAINTENANCE_INVENTORY_SETUP_VIEW : 110, 
    MAINTENANCE_INVENTORY_SETUP_ADD : 111,
    MAINTENANCE_INVENTORY_SETUP_EDIT : 112,
    MAINTENANCE_INVENTORY_SETUP_DELETE : 113,

    MAINTENANCE_INVENTORY_VIEW : 114, 
    MAINTENANCE_INVENTORY_ADD : 115,
    MAINTENANCE_INVENTORY_EDIT : 116,
    MAINTENANCE_INVENTORY_DELETE : 117,

    MAINTENANCE_USER_VIEW : 118,
    MAINTENANCE_USER_ADD : 119,
    MAINTENANCE_USER_EDIT : 120,
    MAINTENANCE_USER_DELETE : 121,

    MAINTENANCE_SERVICE_VIEW : 122,
    MAINTENANCE_SERVICE_ADD : 123,
    MAINTENANCE_SERVICE_EDIT : 124,
    MAINTENANCE_SERVICE_DELETE : 125,

    MAINTENANCE_SERVICE_CATEGORY_VIEW : 126,
    MAINTENANCE_SERVICE_CATEGORY_ADD : 127,
    MAINTENANCE_SERVICE_CATEGORY_EDIT : 128,
    MAINTENANCE_SERVICE_CATEGORY_DELETE : 129,

    MAINTENANCE_PRICE_GROUP_VIEW : 130,
    MAINTENANCE_PRICE_GROUP_ADD : 131,
    MAINTENANCE_PRICE_GROUP_EDIT : 132,
    MAINTENANCE_PRICE_GROUP_DELETE : 133,

    MAINTENANCE_GENERAL_SETTINGS_VIEW : 134,
    MAINTENANCE_GENERAL_SETTINGS_EDIT : 135,

}