import React, { Component } from 'react';

import {
	CircularProgress
} from '@mui/material';

import {
	Visibility,
	VisibilityOff,
	DoNotDisturbAlt,

	ExitToApp,
	FullscreenOutlined,
	FullscreenExitOutlined,
	DarkModeOutlined,
	LightModeOutlined,
	SupportOutlined,
	NotificationsNoneOutlined,
	AccountCircleRounded,
	ChatOutlined,
	EventAvailableOutlined,
	SettingsOutlined,
	OutputOutlined,
	HomeOutlined,
	PeopleAltOutlined,
	PersonOutlineOutlined,
	CalendarTodayOutlined,
	DescriptionOutlined,
	InsertDriveFileOutlined,
	AttachMoneyOutlined,
	GridViewOutlined,
	KeyboardArrowRightOutlined,
	PieChartOutlineOutlined,
	KeyboardArrowDownOutlined,
	PersonAddAltOutlined,
	DownloadRounded,
	UploadRounded,
	InsertChartRounded,
	SearchRounded,
	VisibilityOutlined,
	PrintOutlined,
	ClearOutlined,
	ListOutlined,
	MoreHorizOutlined,
	AddOutlined,
	AccessTimeOutlined,
	ArrowBackIosNewOutlined,
	DoneAllOutlined,
	HighlightOffOutlined,
	FilterAltOutlined,
	CheckOutlined,
	PersonSearchOutlined,
	SaveOutlined,
	FeedOutlined,
	CallOutlined,
	CloseOutlined,
	ImageOutlined,
	ErrorOutline,
	EmailOutlined,
	NotInterested,
	SimCard,
	BorderColorOutlined,
	ReportOutlined,
	ArrowUpwardOutlined,
	BorderColor,
	StickyNote2Outlined,
	CancelOutlined,
	HistoryOutlined,
	ManageAccountsOutlined,
	InboxOutlined,
	PhoneOutlined,
	PercentOutlined,
	NoteAddOutlined,
	MonetizationOnOutlined,
	ErrorOutlined,
	BubbleChartOutlined,
	CreditCardOutlined,
	BlockOutlined,
	RemoveCircleOutlineOutlined,
	Repeat,
	IsoOutlined,
	AttachFile,
	MenuOutlined,
	KeyboardArrowLeftOutlined,
} from '@mui/icons-material';

const muiClassName = "MuiSvgIcon-root MuiSvgIcon-fontSizeMedium";

const Cube = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<path d="M256,0L31.528,112.236v287.528L256,512l224.472-112.236V112.236L256,0z M234.277,452.564L74.974,372.913V160.81
					l159.303,79.651V452.564z M101.826,125.662L256,48.576l154.174,77.087L256,202.749L101.826,125.662z M437.026,372.913
					l-159.303,79.651V240.461l159.303-79.651V372.913z"/>
	</svg>
}

const HeartBeat = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
		<polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
	</svg>
}

const AlignVertically = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<g>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M3 11h18v2H3v-2zm15 7v3h-2v-3h-3l4-4 4 4h-3zM8 18v3H6v-3H3l4-4 4 4H8zM18 6h3l-4 4-4-4h3V3h2v3zM8 6h3l-4 4-4-4h3V3h2v3z" />
		</g>
	</svg>
}

const TrashBin = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
		<path d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z" />
	</svg>
}

const Pencil = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="-6.5 0 32 32">
		<path d="M19.28 10.32c0-0.24-0.080-0.44-0.24-0.6l-3.12-3.12c-0.32-0.32-0.84-0.32-1.2 0l-2.36 2.36-11.32 11.36c-0.12 0.12-0.2 0.28-0.24 0.44l-0.8 3.92c-0.040 0.28 0.040 0.56 0.24 0.76 0.16 0.16 0.36 0.24 0.6 0.24 0.040 0 0.12 0 0.16 0l3.92-0.8c0.16-0.040 0.32-0.12 0.44-0.24l13.68-13.68c0.16-0.2 0.24-0.4 0.24-0.64zM4.32 23.24l-2.44 0.48 0.52-2.4 10.56-10.56 1.92 1.92-10.56 10.56zM16.080 11.52l-1.92-1.92 1.2-1.2 1.92 1.92-1.2 1.2z"></path>
	</svg>
}

const Upload = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} viewBox="0 -2 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Icon-Set" transform="translate(-569.000000, -674.000000)" fill="#000000">
				<path d="M579.732,681.7 L583,677.74 L583,691.998 C583,692.552 583.447,693 584,693 C584.553,693 585,692.552 585,691.998 L585,677.702 L588.299,681.7 C588.69,682.095 589.326,682.095 589.719,681.7 C590.11,681.307 590.11,680.668 589.719,680.274 L584.776,674.283 C584.566,674.073 584.289,673.983 584.016,673.998 C583.742,673.983 583.465,674.073 583.256,674.283 L578.313,680.274 C577.921,680.668 577.921,681.307 578.313,681.7 C578.705,682.095 579.341,682.095 579.732,681.7 L579.732,681.7 Z M598,690 C597.447,690 597,690.448 597,691 L597,698 L571,698 L571,691 C571,690.448 570.553,690 570,690 C569.447,690 569,690.448 569,691 L569,699 C569,699.553 569.447,700 570,700 L598,700 C598.553,700 599,699.553 599,699 L599,691 C599,690.448 598.553,690 598,690 L598,690 Z" id="upload"></path>
			</g>
		</g>
	</svg>
}

const ArrowDownBracket = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M7.293,13.707a1,1,0,1,1,1.414-1.414L11,14.586V3a1,1,0,0,1,2,0V14.586l2.293-2.293a1,1,0,0,1,1.414,1.414l-4,4a1,1,0,0,1-.325.216.986.986,0,0,1-.764,0,1,1,0,0,1-.325-.216ZM22,12a1,1,0,0,0-1,1v7H3V13a1,1,0,0,0-2,0v8a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V13A1,1,0,0,0,22,12Z" />
	</svg>
}


const FileList = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path fill="currentColor" d="M19 22H5a3 3 0 0 1-3-3V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12h4v4a3 3 0 0 1-3 3m-1-5v2a1 1 0 1 0 2 0v-2zm-2 3V4H4v15a1 1 0 0 0 1 1zM6 7h8v2H6zm0 4h8v2H6zm0 4h5v2H6z" />
	</svg>
}

const RefreshLine = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.46257 4.43262C7.21556 2.91688 9.5007 2 12 2C17.5228 2 22 6.47715 22 12C22 14.1361 21.3302 16.1158 20.1892 17.7406L17 12H20C20 7.58172 16.4183 4 12 4C9.84982 4 7.89777 4.84827 6.46023 6.22842L5.46257 4.43262ZM18.5374 19.5674C16.7844 21.0831 14.4993 22 12 22C6.47715 22 2 17.5228 2 12C2 9.86386 2.66979 7.88416 3.8108 6.25944L7 12H4C4 16.4183 7.58172 20 12 20C14.1502 20 16.1022 19.1517 17.5398 17.7716L18.5374 19.5674Z"></path>
	</svg>
}

const ServiceLine = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.16113 4.46875C5.58508 2.0448 9.44716 1.9355 12.0008 4.14085C14.5528 1.9355 18.4149 2.0448 20.8388 4.46875C23.2584 6.88836 23.3716 10.741 21.1785 13.2947L13.4142 21.0858C12.6686 21.8313 11.4809 21.8652 10.6952 21.1874L10.5858 21.0858L2.82141 13.2947C0.628282 10.741 0.741522 6.88836 3.16113 4.46875ZM4.57534 5.88296C2.86819 7.59011 2.81942 10.3276 4.42902 12.0937L4.57534 12.2469L12 19.6715L17.3026 14.3675L13.7677 10.8327L12.7071 11.8934C11.5355 13.0649 9.636 13.0649 8.46443 11.8934C7.29286 10.7218 7.29286 8.8223 8.46443 7.65073L10.5656 5.54823C8.85292 4.17713 6.37076 4.23993 4.7286 5.73663L4.57534 5.88296ZM13.0606 8.71139C13.4511 8.32086 14.0843 8.32086 14.4748 8.71139L18.7168 12.9533L19.4246 12.2469C21.1819 10.4896 21.1819 7.64032 19.4246 5.88296C17.7174 4.17581 14.9799 4.12704 13.2139 5.73663L13.0606 5.88296L9.87864 9.06494C9.51601 9.42757 9.49011 9.99942 9.80094 10.3919L9.87864 10.4792C10.2413 10.8418 10.8131 10.8677 11.2056 10.5569L11.2929 10.4792L13.0606 8.71139Z"></path>
	</svg>
}

const User2Line = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H4ZM13 16.083V20H17.6586C16.9423 17.9735 15.1684 16.4467 13 16.083ZM11 20V16.083C8.83165 16.4467 7.05766 17.9735 6.34141 20H11ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.2104 11 16 9.21043 16 7C16 4.78957 14.2104 3 12 3C9.78957 3 8 4.78957 8 7C8 9.21043 9.78957 11 12 11Z"></path>
	</svg>
}

const Key2Line = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M10.7577 11.8281L18.6066 3.97919L20.0208 5.3934L18.6066 6.80761L21.0815 9.28249L19.6673 10.6967L17.1924 8.22183L15.7782 9.63604L17.8995 11.7574L16.4853 13.1716L14.364 11.0503L12.1719 13.2423C13.4581 15.1837 13.246 17.8251 11.5355 19.5355C9.58291 21.4882 6.41709 21.4882 4.46447 19.5355C2.51184 17.5829 2.51184 14.4171 4.46447 12.4645C6.17493 10.754 8.81633 10.5419 10.7577 11.8281ZM10.1213 18.1213C11.2929 16.9497 11.2929 15.0503 10.1213 13.8787C8.94975 12.7071 7.05025 12.7071 5.87868 13.8787C4.70711 15.0503 4.70711 16.9497 5.87868 18.1213C7.05025 19.2929 8.94975 19.2929 10.1213 18.1213Z"></path>
	</svg>
}

const CurrencyLine = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M17.0047 16.0027H19.0047V4.00275H9.00468V6.00275H17.0047V16.0027ZM17.0047 18.0027V21.0019C17.0047 21.5546 16.5547 22.0027 15.9978 22.0027H4.01154C3.45548 22.0027 3.00488 21.5581 3.00488 21.0019L3.00748 7.00362C3.00759 6.45085 3.45752 6.00275 4.0143 6.00275H7.00468V3.00275C7.00468 2.45046 7.4524 2.00275 8.00468 2.00275H20.0047C20.557 2.00275 21.0047 2.45046 21.0047 3.00275V17.0027C21.0047 17.555 20.557 18.0027 20.0047 18.0027H17.0047ZM5.0073 8.00275L5.00507 20.0027H15.0047V8.00275H5.0073ZM7.00468 16.0027H11.5047C11.7808 16.0027 12.0047 15.7789 12.0047 15.5027C12.0047 15.2266 11.7808 15.0027 11.5047 15.0027H8.50468C7.12397 15.0027 6.00468 13.8835 6.00468 12.5027C6.00468 11.122 7.12397 10.0027 8.50468 10.0027H9.00468V9.00275H11.0047V10.0027H13.0047V12.0027H8.50468C8.22854 12.0027 8.00468 12.2266 8.00468 12.5027C8.00468 12.7789 8.22854 13.0027 8.50468 13.0027H11.5047C12.8854 13.0027 14.0047 14.122 14.0047 15.5027C14.0047 16.8835 12.8854 18.0027 11.5047 18.0027H11.0047V19.0027H9.00468V18.0027H7.00468V16.0027Z"></path>
	</svg>
}

const StackLine = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M20.0834 15.1999L21.2855 15.9212C21.5223 16.0633 21.599 16.3704 21.457 16.6072C21.4147 16.6776 21.3559 16.7365 21.2855 16.7787L12.5145 22.0412C12.1979 22.2313 11.8022 22.2313 11.4856 22.0412L2.71463 16.7787C2.47784 16.6366 2.40106 16.3295 2.54313 16.0927C2.58536 16.0223 2.64425 15.9634 2.71463 15.9212L3.91672 15.1999L12.0001 20.0499L20.0834 15.1999ZM20.0834 10.4999L21.2855 11.2212C21.5223 11.3633 21.599 11.6704 21.457 11.9072C21.4147 11.9776 21.3559 12.0365 21.2855 12.0787L12.0001 17.6499L2.71463 12.0787C2.47784 11.9366 2.40106 11.6295 2.54313 11.3927C2.58536 11.3223 2.64425 11.2634 2.71463 11.2212L3.91672 10.4999L12.0001 15.3499L20.0834 10.4999ZM12.5145 1.30864L21.2855 6.5712C21.5223 6.71327 21.599 7.0204 21.457 7.25719C21.4147 7.32757 21.3559 7.38647 21.2855 7.42869L12.0001 12.9999L2.71463 7.42869C2.47784 7.28662 2.40106 6.97949 2.54313 6.7427C2.58536 6.67232 2.64425 6.61343 2.71463 6.5712L11.4856 1.30864C11.8022 1.11864 12.1979 1.11864 12.5145 1.30864ZM12.0001 3.33233L5.88735 6.99995L12.0001 10.6676L18.1128 6.99995L12.0001 3.33233Z"></path>
	</svg>
}

const AdminDashboardTotalDrs = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-plus icon-dual-primary text-primary"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>
}

const AdminDashboardTotalPatients = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user icon-dual-success text-success"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
}

const AdminDashboardMonthlyPatients = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} classxmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check icon-dual-warning text-warning"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
}

const AdminDashboardTotalPanel = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home icon-dual-dark text-dark"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
}

const DoctorDashboardTotalAppointment = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar icon-dual-warning text-warning"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
}

const DoctorDashboardTotalQueue = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users icon-dual-primary text-primary"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
}

const DoctorDashboardTotalPatients = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users icon-dual-success text-success"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
}

const DoctorDashboardTotalPrescription = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit icon-dual-danger text-danger"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
}

const FileToCopy = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg className={cName} fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM5.00242 8L5.00019 20H14.9998V8H5.00242ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6ZM7 11H13V13H7V11ZM7 15H13V17H7V15Z"></path>
	</svg>
}

const Document = ({ className = null }) => {
	var cName = muiClassName + " " + className;

	return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file icon-dual-primary text-primary"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>		
}

export const VisibilityIcon = Visibility,
	VisibilityOffIcon = VisibilityOff,
	CircularProgressIcon = CircularProgress,
	PageNotFoundIcon = DoNotDisturbAlt,
	SignInIcon = ExitToApp,
	FullscreenIcon = FullscreenOutlined,
	FullscreenExitIcon = FullscreenExitOutlined,
	DarkModeIcon = DarkModeOutlined,
	LightModeIcon = LightModeOutlined,
	SupportIcon = SupportOutlined,
	NotificationsIcon = NotificationsNoneOutlined,
	AccountCircleRoundedIcon = AccountCircleRounded,
	ChatOutlinedIcon = ChatOutlined,
	EventAvailableOutlinedIcon = EventAvailableOutlined,
	SettingsOutlinedIcon = SettingsOutlined,
	OutputOutlinedIcon = OutputOutlined,
	HomeOutlinedIcon = HomeOutlined,
	PeopleAltOutlinedIcon = PeopleAltOutlined,
	PersonOutlineOutlinedIcon = PersonOutlineOutlined,
	CalendarTodayOutlinedIcon = CalendarTodayOutlined,
	DescriptionOutlinedIcon = DescriptionOutlined,
	HeartBeatIcon = ({ className = null }) => { return <HeartBeat className={className} /> },
	InsertDriveFileOutlinedIcon = InsertDriveFileOutlined,
	AttachMoneyOutlinedIcon = AttachMoneyOutlined,
	GridViewOutlinedIcon = GridViewOutlined,
	KeyboardArrowRightOutlinedIcon = KeyboardArrowRightOutlined,
	CubeIcon = ({ className = null }) => { return <Cube className={className} /> },
	PieChartOutlineOutlinedIcon = PieChartOutlineOutlined,
	KeyboardArrowDownOutlinedIcon = KeyboardArrowDownOutlined,
	AlignVerticallyIcon = ({ className = null }) => { return <AlignVertically className={className} /> },
	TrashBinIcon = ({ className = null }) => { return <TrashBin className={className} /> },
	PersonAddAltOutlinedIcon = PersonAddAltOutlined,
	DownloadRoundedIcon = DownloadRounded,
	UploadRoundedIcon = UploadRounded,
	InsertChartRoundedIcon = InsertChartRounded,
	SearchRoundedIcon = SearchRounded,
	PencilIcon = ({ className = null }) => { return <Pencil className={className} /> },
	VisibilityOutlinedIcon = VisibilityOutlined,
	PrintOutlinedIcon = PrintOutlined,
	ClearOutlinedIcon = ClearOutlined,
	UploadIcon = ({ className = null }) => { return <Upload className={className} /> },
	ArrowDownBracketIcon = ({ className = null }) => { return <ArrowDownBracket className={className} /> },
	ListOutlinedIcon = ListOutlined,
	MoreHorizOutlinedIcon = MoreHorizOutlined,
	AddOutlinedIcon = AddOutlined,
	AccessTimeOutlinedIcon = AccessTimeOutlined,
	ArrowBackIosNewOutlinedIcon = ArrowBackIosNewOutlined,
	DoneAllOutlinedIcon = DoneAllOutlined,
	HighlightOffOutlinedIcon = HighlightOffOutlined,
	FilterAltOutlinedIcon = FilterAltOutlined,
	CheckOutlinedIcon = CheckOutlined,
	PersonSearchOutlinedIcon = PersonSearchOutlined,
	SaveOutlinedIcon = SaveOutlined,
	FeedOutlinedIcon = FeedOutlined,
	CallOutlinedIcon = CallOutlined,
	CloseOutlinedIcon = CloseOutlined,
	ImageOutlinedIcon = ImageOutlined,
	ErrorOutlineIcon = ErrorOutline,
	EmailOutlinedIcon = EmailOutlined,
	NotInterestedIcon = NotInterested,
	SimCardIcon = SimCard,
	CreditCardRoundedIcon = CreditCardOutlined,
	BorderColorOutlinedIcon = BorderColorOutlined,
	ReportOutlinedIcon = ReportOutlined,
	FileListIcon = ({ className = null }) => { return <FileList className={className} /> },
	ArrowUpwardOutlinedIcon = ArrowUpwardOutlined,
	BorderColorIcon = BorderColor,
	StickyNote2OutlinedIcon = StickyNote2Outlined,
	CancelOutlinedIcon = CancelOutlined,
	RefreshLineIcon = ({ className = null }) => { return <RefreshLine className={className} /> },
	HistoryOutlinedIcon = HistoryOutlined,
	InboxOutlinedIcon = InboxOutlined,
	ServiceLineIcon = ({ className = null }) => { return <ServiceLine className={className} /> },
	User2LineIcon = ({ className = null }) => { return <User2Line className={className} /> },
	ManageAccountsOutlinedIcon = ManageAccountsOutlined,
	Key2LineIcon = ({ className = null }) => { return <Key2Line className={className} /> },
	PhoneOutlinedIcon = PhoneOutlined,
	PercentOutlinedIcon = PercentOutlined,
	NoteAddOutlinedIcon = NoteAddOutlined,
	MonetizationOnOutlinedIcon = MonetizationOnOutlined,
	ErrorOutlinedIcon = ErrorOutlined,
	BubbleChartOutlinedIcon = BubbleChartOutlined,
	CurrencyLineIcon = ({ className = null }) => { return <CurrencyLine className={className} /> },
	StackLineIcon = ({ className = null }) => { return <StackLine className={className} /> },
	AdminDashboardTotalDrsIcon = ({ className = null }) => { return <AdminDashboardTotalDrs className={className} /> },
	AdminDashboardMonthlyPatientsIcon = ({ className = null }) => { return <AdminDashboardMonthlyPatients className={className} /> },
	AdminDashboardTotalPanelIcon = ({ className = null }) => { return <AdminDashboardTotalPanel className={className} /> },
	AdminDashboardTotalPatientsIcon = ({ className = null }) => { return <AdminDashboardTotalPatients className={className} /> },
	DoctorDashboardTotalAppointmentIcon = ({ className = null }) => { return <DoctorDashboardTotalAppointment className={className} /> },
	DoctorDashboardTotalPatientsIcon = ({ className = null }) => { return <DoctorDashboardTotalPatients className={className} /> },
	DoctorDashboardTotalPrescriptionIcon = ({ className = null }) => { return <DoctorDashboardTotalPrescription className={className} /> },
	DoctorDashboardTotalQueueIcon = ({ className = null }) => { return <DoctorDashboardTotalQueue className={className} /> },
	FileToCopyIcon = ({ className = null }) => { return <FileToCopy className={className} /> },
	DocumentIcon = ({ className = null }) => { return <Document className={className} /> },
	BlockOutlinedIcon = BlockOutlined,
	RemoveCircleOutlineOutlinedIcon = RemoveCircleOutlineOutlined,
	RepeatIcon = Repeat,
	IsoOutlinedIcon = IsoOutlined,
	AttachFileIcon = AttachFile,
	MenuOutlinedIcon = MenuOutlined,
	KeyboardArrowLeftOutlinedIcon = KeyboardArrowLeftOutlined;


