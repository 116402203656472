import LocalStorageHelper from "../LocalStorageHelper";

import { 
    AccessEnum,
    QueueAccessEnum,
    PatientAccessEnum,
    ReportsAccessEnum,
    InvoicesAccessEnum,
    InventoryAccessEnum,
    AppointmentAccessEnum,
    MaintenanceAccessEnum,
    PrescriptionAccessEnum,
} from '../../utilities/Enums/AccesibilityEnum';

import { MenuItems } from "../../components/CommonComponents/Layout/NavigationMenu/MenuItems";
import { RoleEnum } from "../../utilities/Enums/RoleEnum";

export const AccessHelper = {
    hasAccess: function (accessEnum, enqueueSnackbar = null) {
        // return true;

        const userData = LocalStorageHelper.getUserData();

        var accessModules = userData && userData.modules ? userData.modules : [];
        var hasAccess = false;
        
        if (accessModules && accessModules.length > 0) {
            hasAccess = accessModules.find(x => x == accessEnum)
            if (hasAccess == accessEnum && accessEnum == AccessEnum.DASHBOARD){
                hasAccess = true;
            }
        }

        if (!hasAccess && enqueueSnackbar != null) {
            enqueueSnackbar("You don't have access for this. Contact your Admin to get permission", { variant: 'error' });
        }
        return hasAccess;
    },
    hasAccessMenu: function () {
        
        const userData = LocalStorageHelper.getUserData();
        // var accessModules = userData.roleId == RoleEnum.SUPER_ADMIN || userData.roleId == RoleEnum.LEAD_ADMIN ? [...Array(141).keys()] : [...Array(95).keys()];
        var accessModules = userData && userData.modules ? userData.modules : [];
        let _MenuItems = [];
        let _menu = [];
        let _subMenu = [];
        let _ssubMenu = [];

        MenuItems.forEach(_menuItems => {
            if(_menuItems.hasSubMenu){
                let _subMenuItems = [];

                _menuItems.subMenuItems.forEach(_smItems => {
                    let item = accessModules.filter(menu => menu == _smItems.accessId);
                    if(_smItems.hasSubMenu){
                        let _ssubMenuItems = [];

                        _smItems.subMenuItems.forEach(_ssmItems => {
                            let item = accessModules.filter(menu => menu == _ssmItems.accessId);
                    
                            if(item.length > 0){
                                _ssubMenu = _ssmItems;
                                _ssubMenuItems.push(_ssubMenu);
                            }
                        });
                    
                        if(_ssubMenuItems.length > 0){
                            _ssubMenu = _smItems;
                            _ssubMenu.subMenuItems = _ssubMenuItems;
                            _subMenuItems.push(_ssubMenu);
                        }
                    }
                    
                    if(item.length > 0){
                        _subMenu = _smItems;
                        _subMenuItems.push(_subMenu);
                    }
                })

                if(_subMenuItems.length > 0){
                    _menu = _menuItems;
                    _menu.subMenuItems = _subMenuItems;
                    _MenuItems.push(_menu);
                }
            }
            else{
                let item = accessModules.filter(menu => menu == _menuItems.accessId);
                if(item.length > 0){
                    _menu = _menuItems;
                    _MenuItems.push(_menu);
                }
            }
            if(_menu.length >0){
                _MenuItems.push(_menu);                    
            }
        })
            
        return _MenuItems;
    },
}

export default AccessHelper;