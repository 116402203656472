import {
    postRequest,
    getRequest,
    uploadRequest,
    deleteRequest,
} from "./apiClient";

//#region 
const GeneralSettingsUrl = 'configsettings';
//#endregion 

const GeneralSettingsService = {
    getGeneralSettings: async () => {
        var _url = GeneralSettingsUrl;
        var res = await getRequest(_url);

        return res;
    },
    putGeneralSettings: async (param) => {
        var _url = GeneralSettingsUrl;
        var res = await uploadRequest(_url, param);

        return res;
    },
    putClinicSettings: async (param, id) => {
        var _url = GeneralSettingsUrl + "/clinic/" + id;
        var res = await uploadRequest(_url, param);

        return res;
    },
}

export default GeneralSettingsService
