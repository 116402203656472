const LocalStorageHelper = {

    //local storage handler
    getToken: function () {
        return localStorage.getItem('token')
    },

    saveToken: function (token) {
        localStorage.setItem('token', token)
    },

    removeToken: function () {
        localStorage.removeItem('token')
    },

    saveUserData: function (data) {
        localStorage.setItem("userData", JSON.stringify(data));
    },

    getUserData: function () {
        return JSON.parse(localStorage.getItem('userData'));
    },

    isLoggedIn: function () {
        return localStorage.getItem('userData') !== null;
    },

    saveDarkMode: function (darModeVal) {
        localStorage.setItem("darkMode", darModeVal)
    },

    isDarkMode: function () {
        return localStorage.getItem('darkMode') == null ? false : localStorage.getItem('darkMode')
    },

    clearLocalStorage: function () {
        localStorage.clear();
    }
}

export default LocalStorageHelper;