import GeneralSettingsService from '../../services/generalsettingsservice';
import LocalStorageHelper from "../LocalStorageHelper";
import { AlproClinicSettings } from "../../AlproClinicSettings";
import { RouteEnum } from '../Enums/RouteEnums';

const SettingsHelper = {
    getSettingsData: async function () {
    	const userData = LocalStorageHelper.getUserData();
        let settingsData = {
            clinicId: (userData && userData.clinicId && userData.clinicId != null && userData.clinicId != "" ?
                userData.clinicId : 0
            ),
	        clinicName: (userData && userData.clinicName && userData.clinicName != null && userData.clinicName != "" ?
                userData.clinicName : "Alpro Clinic"
            ),
	        sidebarLogoLightMode: AlproClinicSettings.ALPROCLINIC_URL + "/assets/alpro-logotype.png",
	        sidebarLogoDarkMode: AlproClinicSettings.ALPROCLINIC_URL + "/assets/alpro-logotype-light.png",
	        sidebarSmallLogoLightMode: AlproClinicSettings.ALPROCLINIC_URL + "/assets/alpro-logotype-sm2.png",
	        sidebarSmallLogoDarkMode: AlproClinicSettings.ALPROCLINIC_URL + "/assets/alpro-logotype-sm2.png",
	        documentLogo: AlproClinicSettings.ALPROCLINIC_URL + "/assets/alpro-logotype.png",
	        favicon: AlproClinicSettings.ALPROCLINIC_URL + "/alproFavico.ico",
	        language: "English",
	        taxName: (userData && userData.clinicTaxName && userData.clinicTaxName != null && userData.clinicTaxName != "" ?
                userData.clinicTaxName : "GST"
            ),
	        taxPercentage: (userData && userData.clinicTaxValue && userData.clinicTaxValue != null && userData.clinicTaxValue != "" ?
                userData.clinicTaxValue : 0
            ),
	    };

	    let _getConfigSettings = await GeneralSettingsService.getGeneralSettings();
        if (_getConfigSettings.errorCode == 401) {
            
        } else if (_getConfigSettings && _getConfigSettings.data && _getConfigSettings.data.data && _getConfigSettings.data.data.length > 0) {
            _getConfigSettings.data.data.forEach(item => {
                if (item.key && item.key != null && item.key != "") {
                    switch (item.key) {
                        case "sidebarLogoLightMode":
                        case "sidebarLogoDarkMode":
                        case "sidebarSmallLogoLightMode":
                        case "sidebarSmallLogoDarkMode":
                        case "documentLogo":
                        case "favicon":
                            if (item.fileUrl && item.fileUrl != null && item.fileUrl != "") {
                                settingsData[item.key] = AlproClinicSettings.API_URL + "/api/" + item.fileUrl;
                            }
                            break;
                        case "language":
                            // case "taxName":
                            // case "taxPercentage":
                            settingsData[item.key] = item.value;
                            break;
                    }
                }
            });
        }
        return { data: settingsData, errorCode: _getConfigSettings.errorCode, isSuccess: _getConfigSettings.isSuccess };
    }
}

export default SettingsHelper;