export const QueueStatusEnum = {
    WAITING: 1,
    ENGAGED: 2,
    BILLED: 3,
    PAID: 4,
    COMPLETED: 5,
    DELETED: 6
}

export const InvoicesStatusEnum = {
    UNPAID: 'unpaid',
    PAID: 'paid',
}

export const InvoicesIntStatusEnum = {
    UNPAID: 0,
    PAID: 1
}

export const CreditNoteStatusEnum = {
    REFUNDED: 'refunded',
    PENDING: 'pendingRefund'
}

export const CreditNoteIntStatusEnum = {
    PENDING: 0,
    REFUNDED: 1,
}

export const InventoryStatusEnum = {
    ACTIVE: 'active',
    INACTIVE: 'inactive'
}

export const PurchaseOrderStatusEnum = {
    OPEN: 'open',
    CLOSED: 'closed'
}

export const PurchaseOrderIntStatusEnum = {
    OPEN: 0,
    CLOSED: 1
}

export const InternalStockStatusEnum = {
    ACKNOWLEDGED: 'acknowledged',
    PENDING: 'pending'
}

export const InternalStockIntStatusEnum = {
    PENDING: 0,
    ACKNOWLEDGED: 1,
    VOID: 2,
    REJECT: 3,
}

export const MaintenanceStatusEnum = {
    ACTIVE: "active",
    INACTIVE: "inactive"
}

export const FilterStatusEnum = {
    INACTIVE: 0,
    ACTIVE: 1,
    ALL: 2
}

export const GoodsReturnStatusEnum = {
    COMPLETED: 1,
    PENDING: 0,
    CANCELLED: 2
}