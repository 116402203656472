import React, { useState, Suspense, useEffect } from 'react';
import './App.scss';
import { withCookies, useCookies } from 'react-cookie';
import {
    QueryCache,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'

import Loader from './components/CommonComponents/Loader/Loader';

import PageLayout from './components/CommonComponents/Layout/PageLayout/PageLayout';


import LocalStorageHelper from './utilities/LocalStorageHelper';
import { useSnackbar } from 'notistack';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
            refetchOnReconnect: true,
            keepPreviousData: true,
            staleTime: 5 * 60 * 1000,
            cacheTime: 15 * 60 * 1000
            // networkMode: 'offlineFirst'
        },
    },
    queryCache: new QueryCache({
        onError: (error) => {
            console.error('Caching Error: ', error.message);
        }
    }),
})

const App = () => {
    const userData = LocalStorageHelper.getUserData();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // Use states
    const [toggledMenu, setToggledMenu] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(LocalStorageHelper.isLoggedIn());
    const [isDarkMode, setDarkMode] = useState(LocalStorageHelper.isDarkMode() === 'true');
    const [loadingState, setLoadingState] = useState(false);
    const [GettingSettings, setGettingSettings] = useState(false);

    //Variables
    const [cookies, setCookie, removeCookie] = useCookies(['navigatedPages']);
    if (isDarkMode) {
        document.body.classList.add('darkMode');
    }

    // useEffect(() => {
    //     getSettingsData();
    //     getSystemGeneralSettings();
    //     dispatchGetAlproClinicSettings();
    //     loginChecker();
    // }, []);

    useEffect(() => {
        setGettingSettings(true);
        // getSystemGeneralSettings();
    }, []);

    // Event Handler Fuctions
    // const getSettingsData = async () => {
    //     setLoadingState(true);
    //     let getSettings = await SettingsHelper.getSettingsData();

    //     setDefaultGeneralSettings(getSettings);
    //     setLoadingState(false);
    // }

    const loginChecker = async () => {
        let _proceed = false;
        let _pathName = window.location.pathname;

        

        // if (isForgotPassword) {
        //     navigate(RouteEnum.FORGOT_PASSWORD)
        // } else {
        //     if (LocalStorageHelper.isLoggedIn()) {
                
        //     } else {
        //         window.history.replaceState(null, AlproClinicSettings.APPNAME, "/");
        //         document.title = AlproClinicSettings.APPNAME;
        //         LocalStorageHelper.clearLocalStorage();
        //         removeCookie("navigatedPages");
        //         removeCookie("pageId");
        //         removeCookie("pageTab");
        //         navigate(RouteEnum.LOGIN);
        //     }
        // }
}
    
    // const redirectPage = () => {
    //     if (document.referrer == null || document.referrer == "") {
    //         //page was fresh or url was input manually
    //         //todo incorrect breadcrumbs
    //     } else if (cookies.navigatedPages) {
    //         for (const [pageKey, pageVal] of cookies.navigatedPages.entries()) {
    //             if (pageVal.activeTab) {
    //                 window.history.replaceState(null, pageVal.tabName, pageVal.link);
    //                 document.title = `${pageVal.linkName} - ${AlproClinicSettings.APPNAME}`;
    //                 window.history.pushState("", "", pageVal.link);
    //             }
    //         }
    //     } else {
    //         // window.history.replaceState(null, "Dashboard", "/");
    //         // document.title = `Dashboard - ${AlproClinicSettings.APPNAME}`;
    //         // window.history.pushState("", "", "/");
    //     }
    // }

    const updateActiveTab = (currentNavigatedPages, navigatedTo) => {
        for (const [pageKey, pageValue] of currentNavigatedPages.entries()) {
            pageValue.activeTab = (pageValue.linkName === navigatedTo.linkName ?
                true : false
            );
        }

        return currentNavigatedPages;
    }

    let pageLayoutProps = {
        updateActiveTab: updateActiveTab,
        darkMode: isDarkMode,
        setDarkMode: setDarkMode,
        toggledMenu: toggledMenu,
        setToggledMenu: setToggledMenu,
        loadingState: loadingState,
        setLoadingState: setLoadingState
    }

    //Rendering Functions

    return <QueryClientProvider client={queryClient}>
        <div className={`App${(isDarkMode ? ' darkMode' : '')}`}>
        {/* <Suspense fallback={<Loader />}> */}
        <Suspense fallback={<></>}>
                
                    <PageLayout {...pageLayoutProps} />

            {loadingState ?
                <Loader /> : ""
            }
        </Suspense>
    </div >
    
    </QueryClientProvider >
}

export default withCookies(App);